import React from "react";
import "./Footer.css";
import { Link } from "react-scroll";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="company-details">
          <h2>Raysolve Pty Ltd</h2>
        </div>

        <div className="quick-links">
          <h3>Quick Links</h3>
          <ul>
            <li>
              <Link to="aboutus-section" smooth={true} duration={500}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="services-section" smooth={true} duration={500}>
                Services
              </Link>
            </li>
            <li>
              <Link to="contact-form-section" smooth={true} duration={500}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

        <div className="contact-details">
          <h3>Contact Us</h3>
          <p>+27 81 043 7185</p>
          <p>info@raysolve.com</p>
        </div>

        <div className="social-media">
          {/* Add social media links/icons if needed */}
        </div>

        <div className="copyright">
          © 2023 Raysolve Pty Ltd. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
