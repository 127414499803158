import React from "react";
import "./AboutUs.css";

function AboutUs() {
  return (
    <section className="aboutus-section">
      <h2 className="aboutus-header">About Raysolve Pty Ltd</h2>
      <div className="aboutus-content">
        <div className="card about_us_main">
          <h3 className="about_us_header">Empowering Innovation and Expertise</h3>
          <p className="aboutus-intro">
            At Raysolve Pty Ltd, our core mission is to empower businesses across various sectors with innovative solutions and expert consultation. We specialize in a wide range of services, including IT projects & consultation, medical supplies, construction and development, telecommunications services, and more. Our approach combines cutting-edge technology with strategic insights to help our clients navigate their unique challenges and achieve sustainable growth.
          </p>
        </div>
        <div className="card service-focus-card">
          <h3>Service Excellence</h3>
          <p>
            Our dedication to service excellence is at the heart of everything we do. With a diverse portfolio spanning from IT projects & consultation to essential medical supplies, we provide tailored solutions that meet the precise needs of our clients, ensuring quality, reliability, and innovation in every project we undertake.
          </p>
        </div>

        <div className="card industry-solutions-card">
          <h3>Industry-Specific Solutions</h3>
          <p>
            Understanding the unique demands of each industry, Raysolve offers specialized services designed to address the specific challenges of sectors such as healthcare, telecommunications, construction, and more. Our team leverages extensive expertise to deliver solutions that drive efficiency, enhance operations, and foster development.
          </p>
        </div>

        <div className="card commitment-card">
          <h3>Our Commitment</h3>
          <p>
            Raysolve's commitment to our clients is unwavering. We strive to be more than just a service provider — we aim to be your trusted partner in innovation and growth. Through collaboration and our dedication to your success, we work tirelessly to deliver results that exceed expectations and create lasting value.
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
