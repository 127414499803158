import React from 'react';
import './ContactForm.css';

function ContactForm() {
  return (
    <section className="contact-form-section">
      <div className="contact-card">
        <h2>Raysolve Pty Ltd</h2>
        <p><strong>Director:</strong> Donovan Govindraju</p>
        <p><strong>Reg number:</strong> 2023/519225/07</p>
        <p><strong>Tel:</strong> +27 81 043 7185</p>
        <p><strong>Email:</strong></p>
        <ul>
          <li>info@raysolve.com</li>
          <li>order@raysolve.com</li>
          <li>quotes@raysolve.com</li>
          <li>accounts@raysolve.com</li>
          <li>sales@raysolve.com</li>
        </ul>
      </div>
    </section>
  );
}

export default ContactForm;
