import React from "react";
import "./Services.css";
import { FaLaptop, FaBullhorn, FaBuilding, FaPhoneAlt, FaBriefcaseMedical, FaCar, FaUtensils } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Services() {
  const servicesData = [
    {
      title: "IT Projects & Consultation",
      icon: <FaLaptop />,
      backgroundColor: "rgb(32,171,197)",
      services: [
        "Business Continuity & Disaster Recovery",
        "IT Strategy & Governance",
        "Information Security",
        "IT Infrastructure",
        "Enterprise Architecture and Service",
        "IT Compliance & Standardization",
      ],
    },
    {
      title: "Events Planning",
      icon: <FaBullhorn />,
      backgroundColor: "rgb(202,130,22)",
      services: [
        "Corporate Events and Conferences",
        "Product Launches",
        "Team Building Events",
        "Business Dinners and Meetings",
      ],
    },
    {
      title: "Construction and Development",
      icon: <FaBuilding />,
      backgroundColor: "rgb(32,171,197)",
      services: [
        "Project Management",
        "Architectural Design",
        "Commercial and Residential Construction",
        "Infrastructure Development",
      ],
    },
    {
      title: "Telecommunications Services",
      icon: <FaPhoneAlt />,
      backgroundColor: "rgb(202,130,22)",
      services: [
        "Network Infrastructure",
        "VoIP Services",
        "Telecommunications Consulting",
        "Wireless & Mobile Solutions",
      ],
    },
    {
      title: "Medical Supplies",
      icon: <FaBriefcaseMedical />,
      backgroundColor: "rgb(32,171,197)",
      services: [
        "Hospital Equipment",
        "Diagnostic Supplies",
        "Surgical Instruments",
        "Pharmaceutical Products",
      ],
    },
    {
      title: "Vehicle Rentals",
      icon: <FaCar />,
      backgroundColor: "rgb(202,130,22)",
      services: [
        "Short and Long-term Rentals",
        "Luxury and Utility Vehicles",
        "Fleet Management Services",
        "Custom Rental Packages",
      ],
    },
    {
      title: "Food Supplies",
      icon: <FaUtensils />,
      backgroundColor: "rgb(32,171,197)",
      services: [
        "Bulk Food Products",
        "Catering Supplies",
        "Specialty Foods",
        "Supply Chain Management for Restaurants and Hotels",
      ],
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="wrapper_main">
      <section className="services-section">
        <h2 className="services-header">Our Services</h2>
        <Slider {...settings}>
          {servicesData.map((section, index) => (
            <div key={index} className="service-card" style={{ backgroundColor: section.backgroundColor }}>
              <div className="icon-text-area">
                <div className="service-icon">{section.icon}</div>
                <h3 className="service-title">{section.title}</h3>
              </div>
              <ul className="service-list">
                {section.services.map((service, idx) => (
                  <li key={idx} className="service-item">{service}</li>
                ))}
              </ul>
            </div>
          ))}
        </Slider>
      </section>
    </div>
  );
}

export default Services;
