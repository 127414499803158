import React from "react";
import "./HeroSection.css";
import videoBack from "./video_back.mp4"; // Ensure the path to your video is correct

function HeroSection() {
  return (
    <section className="hero">
      <video autoPlay loop muted className="hero-video">
        <source src={videoBack} type="video/mp4" />
      </video>
      <div className="hero-overlay"></div>
      <div className="hero-content">
        <div>RAYSOLVE PTY LTD</div>
        <div className="hero-subheadline">INTEGRATED SOLUTIONS & SERVICES</div>
      </div>
    </section>
  );
}

export default HeroSection;
