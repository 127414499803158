import React from 'react';
import './CustomCards.css';
import { FaTree, FaBalanceScale, FaHandshake, FaLightbulb, FaStar } from 'react-icons/fa';

function CustomCards() {

  const cardsData = [
    {
      id: 1,
      title: 'Innovative Solutions',
      description: 'We leverage cutting-edge technology to provide innovative solutions, ensuring our clients stay ahead in a rapidly evolving digital landscape.',
      icon: <FaLightbulb />,
      backgroundColor: 'rgb(25, 42, 86)',
      backgroundColorCard: 'rgb(0, 128, 128)'
    },
    {
      id: 2,
      title: 'Comprehensive Expertise',
      description: 'Our expertise spans IT consultancy, medical supplies, construction, and telecommunications, offering a one-stop solution for diverse needs.',
      icon: <FaStar />,
      backgroundColor: 'rgb(0, 128, 128)',
      backgroundColorCard: 'rgb(25, 42, 86)'
    },
    {
      id: 3,
      title: 'Strategic Partnerships',
      description: 'We believe in building strategic partnerships with our clients, focusing on mutual growth, trust, and achieving collective objectives.',
      icon: <FaHandshake />,
      backgroundColor: 'rgb(25, 42, 86)',
      backgroundColorCard: 'rgb(0, 128, 128)'
    },
    {
      id: 4,
      title: 'Quality and Excellence',
      description: 'Quality is at the core of our services. We strive for excellence in every project, ensuring top-notch results that surpass expectations.',
      icon: <FaBalanceScale />,
      backgroundColor: 'rgb(0, 128, 128)',
      backgroundColorCard: 'rgb(25, 42, 86)'
    },
    {
      id: 5,
      title: 'Sustainable Development',
      description: 'Our approach integrates sustainability into our projects, aiming to contribute positively to the environment and society.',
      icon: <FaTree />,
      backgroundColor: 'rgb(25, 42, 86)',
      backgroundColorCard: 'rgb(0, 128, 128)'
    }
  ];
  

  return (
    <div className="custom-cards-container">
      {cardsData.map((card) => (
        <div key={card.id} className="custom-card" style={{ backgroundColor: card.backgroundColor }}>
          <div className="card-icon" style={{backgroundColor: card.backgroundColorCard}}>{card.icon}</div>
          <h3>{card.title}</h3>
          <p>{card.description}</p>
        </div>
      ))}
    </div>
  );
}

export default CustomCards;
